import "$styles/index.css"

import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.data('transitions', () => ({
  zoomUpTransition: {
    'x-transition:enter' () { return 'transition ease-out duration-300' },
    'x-transition:enter-start' () { return 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' },
    'x-transition:enter-end' () { return 'opacity-100 translate-y-0 sm:scale-100' },
    'x-transition:leave' () { return 'transition ease-in duration-200' },
    'x-transition:leave-start' () { return 'opacity-100 translate-y-0 sm:scale-100' },
    'x-transition:leave-end' () { return 'opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95' },
  },
  fadeOpacityTransition: {
    'x-transition:enter' () { return 'transition ease-out duration-300' },
    'x-transition:enter-start' () { return 'opacity-0' },
    'x-transition:enter-end' () { return 'opacity-100' },
    'x-transition:leave' () { return 'transition ease-in duration-200' },
    'x-transition:leave-start' () { return 'opacity-100' },
    'x-transition:leave-end' () { return 'opacity-0' },
  },
  fadeScaleTransition: {
    'x-transition:enter' () { return 'duration-300 ease-out' },
    'x-transition:enter-start' () { return 'opacity-0 scale-95' },
    'x-transition:enter-end' () { return 'opacity-200 scale-100' },
    'x-transition:leave' () { return 'duration-200 ease-in' },
    'x-transition:leave-start' () { return 'opacity-100 scale-100' },
    'x-transition:leave-end' () { return 'opacity-0 scale-95' },
  },
}))
Alpine.start()

// Import all JavaScript & CSS files from src/_components
import components from "$components/**/*.{js,jsx,js.rb,css}"
